// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Owl
@import 'owl/owl.carousel.css';
@import 'owl/owl.theme.default.css';

$fa-font-path:        "../webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";

// Constante

$primary-font: 'Open Sans', sans-serif;

$primary-color: #F5F5F7;
$secondary-color: #36393A;
$tertiary-color: #979797;
$box-color: #DED5C9;
$hover-color: #44BCDD;

html {
    scroll-behavior: smooth;
}

// Page
body {
    font-family: $primary-font;
    background-color: $primary-color;
	display: flex;
    min-height: 100vh;
    flex-direction: column;

    .container-sm {
        padding:0;
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: 992px) {
        .container-sm {
            max-width: 960px;
        }
    }
    
    @media (max-width: 960px){
        .container-sm {
            padding: 10px;
        }
    }
}

.row {
    margin: 0;
}

.container-sm h1 {
	position: relative;
	font-family: $primary-font;
	font-weight: 700;
	font-size: 22pt;
	text-transform: uppercase;
	color: $secondary-color;
}

.container-sm h1:after {
	content: "";
	background: $secondary-color;
	position: absolute;
	top: -8px;
	left: 0;
	height: 3px;
	width: 40px;
}

// Footer
footer {
    margin-top: auto;
    
    .fa-stack:hover{
        color: $hover-color;
    }

    a {
        font-size: 0.5rem; 
        color: $tertiary-color; 
        text-decoration: none; 
        padding: 0.5em; 
    }

    p {
        font-family: $primary-font;
        color: $secondary-color;
        font-size: 10pt;
        font-weight: 400;
    }

    > .container-sm {
        border-top: thin solid $tertiary-color;
        padding: 10px;
    }

    .copyright {
        margin-bottom: 0px;
    }
    
    .madeby {
        font-size: 7pt !important;
    }
}

// Navbar
.navbar {
    padding: 0;
    margin-bottom: 20px;
	min-height: 80px !important;
	background-image: none;
	background-repeat: no-repeat;
	border-top: 0 !important;
	border-left: 0 !important;
	border-right: 0 !important;
	border-bottom: 0 !important;
	border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;

    .navbar-brand img {
        max-width: 100%;
        max-height: 60px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .navbar-collapse {
        font-family: $primary-font;
        font-size: 11pt;
        font-weight: 800;
        text-transform: uppercase;
        padding-right: 0;
    }

    .navbar-nav {
        > li {
            > .nav-link {
                color: $secondary-color !important;
                border-bottom: 1px solid transparent;
                padding: 0 !important; /*removing spacing*/
                padding-top: 10px !important;
                padding-bottom: 10px !important;
                margin: 15px !important; /*add spacing*/
            }

            > a:hover {
                span {
                    padding-bottom: 1px !important;
                    border-bottom: solid 2px !important;
                }
            }

            > a:focus {
                span { 
                    padding-bottom: 1px !important;
                    border-bottom: solid 2px !important;
                }
            }

            a.active {
                span {
                    padding-bottom: 1px !important;
                    border-bottom: solid 2px !important;
                }
            }
        }
    }
    
    .navbar-toggler {
        border-radius: 0;
    }

    > .container-fluid {
        padding:0 !important;
    }
}


// Carousel
.carousel {
    .carousel-image {
        background-size: contain !important;
        width: 100%;
        height: 500px;
        background-position: center !important;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    @media (max-width:499px){
        .carousel-image {
            background-size:contain !important;
            background-position: center !important;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            width: 100%;
            height: 200px;
        }
    }

    .carousel-inner > .item > .img-responsive {
        width: auto;
        max-height: 800px;
    }
    
    .carousel-indicators {
        li {
            width: 10px !important;
            height: 10px !important;
            margin: 1px !important;
            border: solid 2px $tertiary-color !important;
            border-radius: 50% !important;
            border-color: $secondary-color !important;
            box-sizing: border-box !important;
            background-clip: border-box !important;
        }
        .active {
            margin: 1px !important;
            background-color: $secondary-color;
        }
    }
    
    .carousel-caption {
        
        bottom: 30px;
        position: relative;
        left: auto;
        right: auto;
        color: $secondary-color;
        text-shadow: 0 0 0;
    
        a {
            left: auto;
            right: auto;
            color: $secondary-color;
            text-shadow: 0 0 0;
            text-decoration-line: none;
        }
    
        a:hover {
            text-decoration-line: underline;
        }
    
        h2 {
            position: relative;
            margin-bottom: -8px;
            font-family: 'Open Sans', sans-serif;
            font-size: 16pt;
            font-weight: bold;
            left: auto;
            right: auto;
            color: $secondary-color;
            text-shadow: 0 0 0;
        }
    
        h6 {
            position: relative;
            margin-top: 10px;
            margin-bottom: -5px;
            font-family: 'Open Sans', sans-serif;
            font-size: 10pt;
            font-weight: bold;
            left: auto;
            right: auto;
            color: $secondary-color;
            text-shadow: 0 0 0;
        }
    } 
}

.container-sm .text {
	padding: 0;
	padding-right: 30px;
	padding-bottom: 30px;
}

.container-sm p {
	font-family:  $primary-font;
	font-weight: 400;
	font-size: 10pt !important;
	color: $secondary-color;
}

.news {
	font-family: $primary-font !important;
	font-weight: 400;
	font-size: 10pt;
	color: $secondary-color;

    @media (max-width:992px) {
        td {
            display:block;
            width: 100% !important;
        }
    }

}

.btn-styled {
	font-family: $primary-font;
	font-weight: 500;
	font-size: 10pt;
	background-color: $secondary-color;
	color: $primary-color;
	margin-top:10px;
	margin-bottom:20px;
	border: none;
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 20px;
	padding-right: 20px;
    text-decoration: none;
    border-radius: 0;
}

.program_short {
	width: 85%;
	margin: 0 auto;
	margin-top: 26px/*68px*/;
	margin-bottom: 50px;
	background-color: $box-color;
	padding-left: 30px;
	padding-top: 18px;
	min-height: 200px;
    h2 {
        text-transform: uppercase;
        font-family: $primary-font;
        font-weight: 700;
        font-size: 12pt;
        color: $primary-color;
        text-align: left;
        margin-top:0;
        margin-bottom:10px;
    }
    .date_time {
        font-family: $primary-font;
        text-align: left;
        font-weight: 700;
        font-size: 10pt;
        color: $tertiary-color;
        margin-bottom: 0;
        padding-bottom: 10px;
        padding-left:0;
    }
    .title {
        display: inline-block;
        font-family: $primary-font;
        font-weight: 800;
        font-size: 10pt;
        color: $secondary-color;
        margin-bottom: 0;
        padding-bottom: 10px;
        padding-left:0;
    }
    

    .screenings {
        margin-left: 8px;
        margin-right: 8px;
        padding-left:10px;
        min-height: 100px;
        border-left: solid 1px $secondary-color;
    }
}


@media (max-width:499px) {
	.program_short {
        .title {
            text-align: left;
            display: inline-block;
            font-family: $primary-font;
            font-weight: 800;
            font-size: 8pt;
            color: $secondary-color;
            margin-bottom: 0;
            padding-bottom: 25px;
            padding-left:0; 
        }

        .date_time {
            text-align: left;
            font-family:  $primary-font;
            font-weight: 700;
            font-size: 8pt;
            color: $tertiary-color;
            margin-bottom: 0;
            padding-bottom: 0px;
            padding-left:0;
        }
	}
}

.about-row {
    h1 {
        padding-left: 0;
    }
}

.about-picture {
	max-width: 100%;
	margin-bottom:20px;
}
.info-summary {
	
	width: 100%;
	margin: 0 auto;
	margin-bottom: 10px;
	background-color: $box-color;
	margin-left:0;
	margin-right:0 !important;
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 25px;
	min-height: 150px;

    h2 {
        text-transform: uppercase;
        font-family: $primary-font;
        font-weight: 700;
        font-size: 12pt;
        color: $primary-color;
        text-align: left;
        margin-top:0;
        margin-bottom:10px;
    }

    p {
        font-family: $primary-font;
        font-weight: 600;
        font-size: 10pt;
        color: $secondary-color;
        margin-top:0;
        padding-bottom:30px;
    }
}

.over {
	margin-left: 0;
	padding-left:0;
	padding-right:30px;
}

.mapframe {
	border: 0;
}

.mapHQ {
	background-color: $box-color;
	width: 100%;
	margin: 0 auto;
	margin-top: 0px;
	margin-bottom: 50px;
	margin-left:0;
	margin-right:0 !important;
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 25px;
	padding-bottom: 23px;
	min-height: 200px;
}

// Programma

.rowScreening {
	margin-left:0;
    * {
        padding-left: 0px;
        padding-right: 0px;
    }
}
.title_place {
	padding-left:0;
}
.time_place {
	padding-right:0;
}
.rowScreeningBody {
	margin-bottom: 40px;
}
.rowScreeningHeader h1 {
	text-transform:none;
	margin-bottom: 5px;
}
.rowScreeningHeader h3 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	font-style: italic;
	font-size: 8pt;
	color: $secondary-color;
	margin-top: 0;
}
.rowScreeningHeader h2 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	font-size: 10pt;
	color: $secondary-color;
	text-align: right;
}
.rowScreeningHeader h4 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 10pt;
	color: $secondary-color;
	text-align: right;
}

@media (max-width:992px){
	.title_place {
		padding-left:0;
	}
	.time_place {
		padding-left: 0;
	}
	.rowScreeningHeader h2 {
		font-family: 'Open Sans', sans-serif;
		font-weight: 700;
		font-size: 10pt;
		color: $secondary-color;
		text-align: left;
	}
	.rowScreeningHeader h4 {
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
		font-size: 10pt;
		color: $secondary-color;
		text-align: left;
	}
}
.details {
	margin-top: 15px;
}
.details p {
	margin-bottom: 2px;
}
.screeningLinks {
	margin-top: 10px;
	padding-left: 0;
}
.screeningRechts {
	background-color: $box-color;
	margin-top: 10px;
}
.screeningRechts p {
	padding: 20px;
	padding-top: 18px;
}
.programmaPicture {
	background-size: cover;
	background-position: center center;
	width: 360px;
	height: 360px;		
}

// Cinemas

.divCinema{
	padding-bottom: 116px;
	position: relative;

    > * {
        padding-left: 0;
    }
}
.divCinema h2 {
	font-family: 'Open Sans', sans-serif;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 25px;
	
}
.cinemaPicture {
	position: relative;
	top:0px;
	right:0;
}

.cinemaPicture img{
	max-width: 404px;
}

.divAdres {
	padding-left: 18px;
	border-left: solid 2px $secondary-color;
}

// Contact
.contact-links {
	padding-left: 0;
	padding-right: 60px;
}
.contact h1 {
	margin-bottom: 25px;
    padding-left: 0;
}
.contact-links .form-horizontal {
	padding-right: 20px;
}
.contact-links .form-control{
	border-radius: 0;
	border-color: $secondary-color;
	
}
.contact-links .form-group {
	margin-left:0;
	
}
.contact-rechts {
	padding-left:0;
	margin-right:0;
	padding-right:0;
	
}
.contact-summary {
	width: 85%;
	margin: 0 auto;
	margin-bottom: 50px;
	background-color: $box-color;
	margin-left:60px;
	margin-right:0 !important;
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 25px;
	min-height: 200px;
}
.contact-summary h2{
	text-transform: uppercase;
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	font-size: 12pt;
	color: $primary-color;
	text-align: left;
	margin-top:0;
	margin-bottom:10px;
}
.contact-summary h3{
	text-transform: uppercase;
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	font-size: 11pt;
	color: $primary-color;
	text-align: left;
	margin-top:0;
	margin-bottom:10px;
}
.contact-summary p {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 10pt;
	color: $secondary-color;
	text-align: left;
	margin-top:0;
	padding-bottom:20px;
}
.form-group {
    margin-bottom: 1rem;
}
  
.form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

label {
    margin-bottom: 0.5rem;
}

.owl-carousel .owl-wrapper {
    display: flex !important;
}
.owl-carousel {
    padding-bottom: 5px;
}
.owl-carousel .owl-item img {
    display: block;
    width: auto;
    max-height: 64px;
    margin: 0 auto;
}
.owl-carousel a {
    padding: 0px!important;
}